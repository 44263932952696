.container {
  @apply flex items-center mr-2;
}

.assetLogo svg {
  @apply mr-2 w-5 h-5 bg-gray-80 rounded-full overflow-hidden;
}

.asset {
  @apply font-semibold text-xs;
}
